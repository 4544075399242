<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Diário Atendimento
        </h1>
      </v-col>
    </v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
          <h4>
            Aluno:
            {{ gestaoDeAula.matricula ? gestaoDeAula.matricula.aluno.nomecompleto : "- - -" }}
          </h4>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          Professor:
          {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="data" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <e-label>Selecione uma data</e-label>
              <v-text-field
                v-model="aulaSelecionada.data"
                persistent-hint
                label="Escolha uma data"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="data" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
            <e-label>Selecione um horário</e-label>
            <e-autocomplete
              :items="horarios"
              :error-messages="errors"
              label="Selecione uma opção"
              :item-text="(item) => item.descricao"
              :item-value="(item) => item.id"
              v-model="horarioSelecionado"
              return-object
              dense
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-col>

    <v-col>
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialDiario" :key="index">
          <v-col cols="12">
            <e-label>{{ item.descricao }}</e-label>
            <v-textarea solo dense v-model="aulaSelecionada[item.form]" />
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              @click="createOrUpdate"
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </v-col>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      horarioSelecionado: null,
      aulaSelecionada: {
        data: null,
      },
      form: {
        data: null,
        horario_aee_id: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      ficha: null,
      gestao: null,
      modal: false,
      menu: false,
      horarios: [],
      matricula: null,
      gestao_de_aulas: {},
      submittingForm: false,
      data: null,
    };
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    formatDate(dateString) {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}`;
    },

    async createOrUpdate() {
      this.submittingForm = true;
      try {
        const { id } = this.$route.params;
        const { gestao_id } = this.$route.params;
        this.form.id = id;
        this.form.gestao_id = gestao_id;
        this.form.data = this.formatDate(this.aulaSelecionada.data);
        this.form.horario_aee_id = this.horarioSelecionado.id;

        this.form.matricula_id = this.gestaoDeAula.matricula.id;
        Object.assign(this.form, this.aulaSelecionada);

        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimentoEdit(this.form);

        if (id) {
          this.$toast.info("Diário de atendimento editado!");
        } else {
          this.$toast.info("Diário de atendimento criado!");
        }
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
        this.$router.push({ name: "educacaoInclusiva.fichaDiarioAtendimento" });
      }
    },

    async getFicha() {
      try {
        this.$loaderService.open("Carregando dados...");
        const { id } = this.$route.params;
        const { gestao_id } = this.$route.params;
        const response = await this.$services.gestoesDeAulasAEEService.listarAulasGestao(
          parseInt(gestao_id, 10)
        );
        this.gestaoDeAula = response.payload.gestaoDeAula;

        this.horarios = response.payload.horarios.filter((horario) => horario.descricao);
        const aulaSelecionada =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimento(
            parseInt(id, 10)
          );

        this.horarioSelecionado = aulaSelecionada.horario_aee_id || null;
        return aulaSelecionada;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getFicha();

        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
