import NewEditInventarioHabilidadeEspecialPage from "@/Pages/InventarioHabilidadeEspecial/NewEditInventarioHabilidadeEspecialPage.vue";
import inventarioHabilidadeEspecialIndexPage from "@/Pages/InventarioHabilidadeEspecial/index.vue";

export default [
  {
    path: "/inventario-habilidade/",
    name: "inventarioHabilidadeEspecial",
    component: inventarioHabilidadeEspecialIndexPage,
  },
  {
    path: "/inventario-habilidade/create/:matricula_id",
    name: "inventarioHabilidadeEspecial.create",
    component: NewEditInventarioHabilidadeEspecialPage,
    props: { editing: false },
  },
  {
    path: "/inventario-habilidade/edit/:matricula_id",
    name: "inventarioHabilidadeEspecial.edit",
    component: NewEditInventarioHabilidadeEspecialPage,
    props: { editing: true },
  },
];
