import Axios from "@/plugins/Axios";
import InventarioHabilidadeEspecial from "@/Models/InventarioHabilidadeEspecial";

export class InventarioDeHabilidadeEspecialService {
  async syncAll(matricula) {
    const response = await Axios().post("inventario-habilidade", { id: matricula });
    const payload = response.data;
    const { resumos } = payload;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.sistema.children.map((parent) => {
      return parent.children.map((inventarioHabilidade) => {
        if (!notas[inventarioHabilidade.id]) {
          notas[inventarioHabilidade.id] = {
            matricula_id: payload.matricula.id,
            inventario_habilidade_id: inventarioHabilidade.id,
          };
        }
        return inventarioHabilidade;
      });
    });
    return { ...payload, notas, resumos };
  }

  async syncAllComponenteCurricular(form) {
    const response = await Axios().post(`inventario-habilidade/componente-curricular`, form);
    const payload = response.data;
    return new InventarioHabilidadeEspecial(payload);
  }

  async listarTodos() {
    const response = await Axios().get("inventario-habilidade");
    return response.data;
  }

  async salvarNotas(matricula, notas) {
    await Axios().patch(`inventario-habilidade/${matricula}`, notas);
  }
}
export default new InventarioDeHabilidadeEspecialService();
