import Axios from "@/plugins/Axios";

export class PlanosBimestraisInfantil {
  async planoBimestral(disciplinaId, bimestre, serieId) {
    const response = await Axios().get(
      `planos-bimestrais-infantil/plano-bimestral/${disciplinaId}/${bimestre}/${serieId}`
    );
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post(`planos-bimestrais-infantil/criar-plano-bimestral`, {
      plano,
    });
    return response.data;
  }
}

export default new PlanosBimestraisInfantil();
