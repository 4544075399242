<template>
  <div>
    <span>
      <v-menu v-if="user && $can(['aulas.mudarSituacao'])" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip label :color="color" class="text-center" x-small v-bind="attrs" v-on="on">
            {{ descricaoSituacao }}
          </v-chip>
        </template>
        <v-list>
          <v-list-item
            v-for="situacao of $constants.situacoesAulas"
            :key="situacao.descricao"
            link
            @click="() => verficaMotivo(situacao.descricao)"
          >
            <v-list-item-title>{{ situacao.descricao }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-chip v-else label :color="color" x-small>
        {{ descricaoSituacao }}
      </v-chip>
    </span>
    <v-progress-linear
      v-show="atualizando"
      indeterminate
      striped
      color="primary"
    ></v-progress-linear>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="primary">
              <span class="text-h5 white--text">Informar motivo</span>
            </v-card-title>
            <v-col cols="12">
              <v-select
                v-model="option"
                :items="motivoFalta"
                label="Selecione uma opção"
                outlined
              ></v-select>
              <v-file-input
                v-if="option === 'FALTA COM ATESTADO'"
                label="Anexar atestado"
                filled
                :rules="rules"
                accept="application/pdf"
                @change="handleUploadPdf"
                ref="atestado_ref"
                prepend-icon="mdi-file-pdf-box"
              ></v-file-input>

              <v-textarea
                filled
                autofocus
                v-model="motivo"
                name="input-7-4"
                label="Descreva o motivo da falta"
                value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                dense
              ></v-textarea>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="dialog = false"> Cancelar </v-btn>
              <v-btn
                color="primary"
                text
                @click="() => changeSituacaoJustificacao(descricaoSituacaoJustificativa)"
                :disabled="isButtonDisabled"
              >
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmationDialog" width="500">
          <v-card>
            <v-card-title class="primary">
              <span class="text-h5 white--text">Confirmar Ação</span>
            </v-card-title>
            <v-card-text class="text-body-1 py-4">
              Você deseja deletar o atestado anexado antes de mudar a situação da aula?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="confirmationDialog = false">Cancelar</v-btn>
              <v-btn color="primary" text @click="deleteAtestadoAndProceed()">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    aula: {
      type: Object,
      required: true,
    },
    i: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      aula_local: this.aula,
      atualizando: false,
      descricaoSituacao: this.aula.descricaoSituacao,
      color: this.aula.color,
      dialog: false,
      confirmationDialog: false,
      novaSituacaoParaConfirmacao: null,
      isDeleted: false,
      motivo: null,
      uploadConcluido: false,
      checkbox: true,
      option: this.aula.option || null,
      rules: [
        (v) => !v || v.size < 10000000 || "O pdf deve ter no maximo 10 MB!",
        (v) => !!v || "O pdf é obrigatório!",
      ],
      pdf: null,
      disable: true,
      motivoFalta: this.$constants.motivoFalta,
      descricaoSituacaoJustificativa: null,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    isFaltaComAtestado() {
      return this.option === "FALTA COM ATESTADO";
    },
    isPossuiAtestado() {
      return this.aula_local.atestado !== null;
    },
    isButtonDisabled() {
      return !this.option || !this.motivo;
    },
  },
  watch: {
    selectedOption(novo) {
      return novo;
    },
  },
  methods: {
    async verficaMotivo(novaSituacao) {
      if (
        this.option === "FALTA COM ATESTADO" &&
        this.aula.atestado !== null &&
        novaSituacao !== "Aula rejeitada por falta"
      ) {
        this.novaSituacaoParaConfirmacao = novaSituacao;
        this.confirmationDialog = true;
        return;
      }
      this.motivo = this.aula.motivo;
      if (novaSituacao === "Aula inválida" || novaSituacao === "Aula rejeitada por falta") {
        this.descricaoSituacaoJustificativa = novaSituacao;
        this.dialog = !this.dialog;
        return;
      }
      this.changeSituacao(novaSituacao);
    },
    async deleteAtestadoAndProceed() {
      this.confirmationDialog = false;
      this.isDeleted = true;
      this.changeSituacao(this.novaSituacaoParaConfirmacao);
    },
    handleUploadPdf(file) {
      if (file === null) {
        this.aula_local.atestado = null;
        this.disable = true;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.aula_local.atestado = reader.result;
      };
      this.disable = false;
    },
    visualizarAtestado() {
      window.open(this.aula_local.atestado_path, "_blank");
    },
    async changeSituacaoJustificacao(novaSituacao) {
      this.dialog = !this.dialog;
      this.aula_local.motivo = this.motivo;
      this.aula_local.option = this.option;

      this.atualizando = true;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula_local,
          novaSituacao
        );
        this.uploadConcluido = true;
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
    async changeSituacao(novaSituacao) {
      this.aula_local.motivo = this.motivo;
      this.aula_local.option = this.option;
      try {
        const aulaAtualizada = await this.$services.aulasService.atualizarSituacao(
          this.aula_local,
          novaSituacao
        );
        this.uploadConcluido = true;
        this.$emit("alterarSituacao", { aulaAtualizada, i: this.i });
        this.descricaoSituacao = aulaAtualizada.descricaoSituacao;
        this.color = aulaAtualizada.color;
      } catch (error) {
        this.$handleError(error);
      }
      this.atualizando = false;
    },
  },
};
</script>
